import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
const IndexPage = ({ data }) => {
  return (

    <div id="page">
    <div id="page-wrapper" className="pt-5">


    <div className="mt-5 mb-5 text-center p-2 m-2">
    <h1 className="titling pt-5">Cookie Policy</h1>

    <div class="content section text-center pt-4">
    <h3 className="text-left">
    How we use cookies
    </h3>
    <p>
A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
 </p>
<p className="text-left">
We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
</p>
<p className="text-left">
Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
</p>
<p className="text-left">
You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
    </p>
    <p>
    <h4>Cookies used on this site</h4>
    <ul>
    <li>
    Technical cookies: those cookies are mandatory for the site to work<br/>
     <strong>lilley_mansion_d </strong> - session cookie, expires after exiting the browser.<br/>
      <strong>LilleyMansion </strong> - Cookie banner settings, expire after 365 days
    </li>
    <li>
    Statistic cookies: those cookies are set after your agreement<br/>
     <strong>_ga_N86ZNNCMG6 </strong> - Google Analytics, expires after a month.<br/>
     <strong>_ga </strong> - Google Analytics, expires after a month.
    </li>
    </ul>
    </p>
        </div>
        </div>

    </div>
    </div>


  )
}

export default IndexPage

IndexPage.Layout = Layout

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
